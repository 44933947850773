export const NavItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About',
    path: '/about',
  },
  {
    title: 'Portfolio',
    path: '/#portfolio',
  },
  {
    title: 'News',
    path: '/news',
  },
  {
    title: 'Contact',
    path: '/contact',
  }
];

import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

export const Section4 = () => {
  return (
    <div className={styles.section}>
      <div className={styles.topSection}>
        <Fade>
          <h3>
            INVESTMENT STARTING<br/>FROM €100,000
          </h3>
        </Fade>
      </div>
      <div className={styles.bottomSection}>
        <Fade left>
          <p>
            When we invest in startups, we are normally in the phase between the seed and series A. This is obviously different in VC or PE-backed companies as the setup and needs in those cases is different.
          </p>
        </Fade>
      </div>
    </div>
  )
}

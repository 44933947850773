
import ImgPreSeed from "../../../assets/investment/Preseed.svg";
import ImgSeed from "../../../assets/investment/Seed.svg";
import ImgSeriesA from "../../../assets/investment/Series A.svg";
import CloseIcon from "../../../assets/investment/CloseIcon.svg";
import styles from "./styles.module.scss";

interface InvestmentStateModalProps {
  onClose: () => void;
}

const InvestmentStageModal = ({onClose}: InvestmentStateModalProps) => {
  return (
    <div className={styles.popupContainer}>
      <div className={styles.container}>
        <div className={styles.closeContainer} onClick={onClose}>
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <span className={styles.title}>Investment Stages</span>
        <div className={styles.body}>
          <div className={styles.item}>
            <img src={ImgPreSeed} alt="pre-seed" />
            <p>Pre-Seed</p>
          </div>
          <div className={styles.item}>
            <img src={ImgSeed} alt="pre-seed" />
            <p>Seed</p>
          </div>
          <div className={styles.item}>
            <img src={ImgSeriesA} alt="pre-seed" />
            <p>Series A</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentStageModal;
import LayoutDefault from "../../layout/layout-default";
import styles from './styles.module.scss';

const Custom404Page = () => {

  return (
    <LayoutDefault>
      <div className={styles.container}>
        <h3>404 Page not found</h3>
      </div>
    </LayoutDefault>
  )
};

export default Custom404Page;

import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

export const Section4 = () => {
  return (
    <div className={styles.section}>
      <Fade left>
        <p>
          Most importantly, we know how you feel as a founder, an investor, a buyer and a leader. This is exactly why we are doing this!
        </p>
      </Fade>
    </div>
  )
}

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from 'react-toastify';
import LayoutDefault from "../../../layout/layout-default";
import styles from './styles.module.scss';
import LeftArrow from '../../../assets/news/left-arrow.svg';
import DropZone from "../../../components/dropzone";
import NewsService from "../../../services/news.service";
import { INewsData } from "../../../types/news.type";
import { API_BASE_URL } from "../../../http";

const AdminNewsCreatePage = () => {
  const navigate = useNavigate();
  const {newsId} = useParams();

  const [newsData, setNewsData] = useState<INewsData>();
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [bannerFile, setBannerFile] = useState<File>();
  const [thumbnailImage, setThumbnailImage] = useState<string>();
  const [bannerImage, setBannerImage] = useState<string>();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [length, setLength] = useState('2');
  const [loading, setLoading] = useState(false);

  const [errorImage, setErrorImage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorLink, setErrorLink] = useState('');

  useEffect(() => {
    if (newsId) {
      NewsService.get(newsId).then(response => {
        if (response.data.data) {
          const _newsData = response.data.data;
          setNewsData(_newsData);
          setTitle(_newsData.title);
          setDescription(_newsData.description);
          setLink(_newsData.link);
          setLength(_newsData.length.toString());
          setBannerImage(_newsData.bannerImage);
          setThumbnailImage(_newsData.thumbnail);
        }
      })
    }
  }, [newsId]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleThumbnailFile = (file: File) => {
    setThumbnailFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setThumbnailImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleBannerFile = (file: File) => {
    setBannerFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setBannerImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  const onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }

  const onChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  }

  const onChangeLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLength(event.target.value);
  }

  const onSubmit = () => {
    if (!thumbnailImage || !bannerImage) {
      setErrorImage('Required input field');
      return;
    } else {
      setErrorImage('');
    }
    if (!title) {
      setErrorTitle('Required input field');
      return;
    } else {
      setErrorTitle('');
    }
    if (!description) {
      setErrorDescription('Required input field');
      return;
    } else {
      setErrorDescription('');
    }
    if (!link) {
      setErrorLink('Required input field');
      return;
    } else {
      setErrorLink('');
    }

    const formData = new FormData();
    if (thumbnailFile) {
      formData.append('thumbnail', thumbnailFile);
    }
    if (bannerFile) {
      formData.append('banner', bannerFile);
    }
    formData.append('title', title);
    formData.append('description', description);
    formData.append('link', link);
    formData.append('length', length);
    setLoading(true);
    if (newsId) {
      NewsService.update(formData, newsData?._id).then(data => {
        setLoading(false);
        toast.success('News updated successfully!');
        navigate('/admin/news');
      }).catch(err => {
        setLoading(false);
        console.log(err);
        toast.error('News not updated.');
      })
    } else {
      NewsService.create(formData).then(data => {
        setLoading(false);
        toast.success('News created successfully!');
        navigate('/admin/news');
      }).catch(err => {
        setLoading(false);
        console.log(err);
        toast.error('News not created.');
      });  
    }
  };

  return (
    <LayoutDefault>
      <div className={styles.container}>
        <div className={styles.backButtonContainer}>
          <div className={styles.backButton} onClick={handleBack}>
            <img src={LeftArrow} alt='left-arrow' />
            <p>Back</p>
          </div>
        </div>
        <div className={styles.bannerImageContainer}>
          <div className={styles.thumbnailContainer}>
            <DropZone fileTypes={["JPG", "JPEG", "PNG", "GIF"]} multiple={false} handleChange={handleThumbnailFile}>
              <div className={styles.dropzone}>
                {thumbnailImage ? (
                  <img className={styles.image} src={thumbnailFile ? thumbnailImage: `${API_BASE_URL}${thumbnailImage}`} alt='thumbnail' />
                ) : (
                  <div className={styles.placeholder}>
                    <h3>+</h3>
                    <p>Image</p>
                  </div>
                )}
              </div>
            </DropZone>
          </div>
          <div className={styles.bannerContainer}>
            <DropZone fileTypes={["JPG", "JPEG", "PNG", "GIF"]} multiple={false} handleChange={handleBannerFile}>
              <div className={styles.dropzone}>
                {bannerImage ? (
                  <img className={styles.image} src={bannerFile ? bannerImage : `${API_BASE_URL}${bannerImage}`} alt='banner' />
                ) : (
                  <div className={styles.placeholder}>
                    <h3>+</h3>
                    <p>Image</p>
                  </div>
                )}
              </div>
            </DropZone>
          </div>
        </div>
        {errorImage && <p className={styles.error}>{errorImage}</p>}
        <div className={styles.inputContainer}>
          <input type="text" placeholder="Title" value={title} onChange={onChangeTitle} />
        </div>
        {errorTitle && <p className={styles.error}>{errorTitle}</p>}
        <div className={styles.inputDescription}>
          <textarea placeholder="Body description" onChange={onChangeDescription} value={description} />
        </div>
        {errorDescription && <p className={styles.error}>{errorDescription}</p>}
        <div className={styles.inputContainer}>
          <input type="text" placeholder="Add link (This happens if you click on the images)" value={link} onChange={onChangeLink}/>
        </div>
        {errorLink && <p className={styles.error}>{errorLink}</p>}
        <div className={styles.inputContainer}>
          <input type="number" placeholder="Article length (in minutes)" value={length} onChange={onChangeLength}/>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={onSubmit}>
            {loading ? <ClipLoader color="#ffffff" size={24}/> : 'Submit'}
          </button>
        </div>
      </div>
    </LayoutDefault>
  )
};

export default AdminNewsCreatePage;
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Link} from "react-router-dom";
import {toast} from 'react-toastify';
import LayoutDefault from "../../../layout/layout-default";
import styles from './styles.module.scss';
import NewsService from "../../../services/news.service";
import {INewsData} from "../../../types/news.type";
import {API_BASE_URL} from "../../../http";
import IconFavoriteActive from '../../../assets/admin/favorite-active.svg';
import IconFavorite from '../../../assets/admin/favorite-inactive.svg';
import IconCursor from '../../../assets/admin/select-cursor.svg';
import { convertFromISODateWithFormat } from "../../../utils";


const SectionItem = ({_id, thumbnail, title, length, favorite, updatedAt}: INewsData) => {
  const navigate = useNavigate();

  const onEditItem = () => {
    navigate(`/admin/news/edit/${_id}`)
  };

  const deleteItem = () => {
    NewsService.delete(_id).then(response => {
      toast.success('News deleted successfully!');
      window.location.reload();
    }).catch(err => {
      toast.error('Unexpected error.');
    })
  };

  const onDeleteItem = () => {
    confirmAlert({
      title: 'Confirm action',
      message: 'Are you sure to delete this.',
      buttons: [
        {
          label: 'Yes',
          onClick: deleteItem,
        },
        {
          label: 'No',
        }
      ]
    });
  };

  return (
    <div className={styles.sectionItem}>
      <div className={styles.leftItem}>
        <img src={`${API_BASE_URL}${thumbnail}`} alt='thumbnail' />
        <div className={styles.itemContent}>
          <span className={styles.itemTitle}>
            {title}
          </span>
          <div className={styles.itemFooter}>
            <p>{convertFromISODateWithFormat(updatedAt || '', 'MMM DD YYYY')} . {length} min read</p>
          </div>
        </div>
      </div>
      <div className={styles.rightItem}>
        <img className={styles.actionIcon} src={favorite ? IconFavoriteActive : IconFavorite} alt='favorite' />
        <img className={styles.actionIcon} src={IconCursor} alt='favorite' />
        <button className={styles.editButton} onClick={onEditItem}>Edit</button>
        <button className={styles.deleteButton} onClick={onDeleteItem}>Delete</button>
      </div>
    </div>
  )
};

const AdminNewsPage = () => {
  const [newsData, setNewsData] = useState<INewsData[]>([]);

  useEffect(() => {
    NewsService.getAll()
      .then(response => {
        if (response.data.list) {
          setNewsData(response.data.list);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <LayoutDefault>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span className={styles.sectionTitle}>
              All News
            </span>
            <Link to={'/admin/news/create'}>
              <button className={styles.createButton}>Create New</button>
            </Link>
          </div>
          <div className={styles.sectionBody}>
            {newsData?.map((item, index) => <SectionItem key={`section-mock-${index}`} {...item} />)}
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
};

export default AdminNewsPage;

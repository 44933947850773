import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

interface SectionCardProps {
  icon: string;
  description: string;
}

const data = [
  {
    icon: require('../../../../assets/about/person.png'),
    description: 'Board position',
  },
  {
    icon: require('../../../../assets/about/person.png'),
    description: 'Investors',
  },
  {
    icon: require('../../../../assets/about/person.png'),
    description: 'Operationally',
  },
  {
    icon: require('../../../../assets/about/person.png'),
    description: 'Advisors',
  },
];

const SectionItem = ({icon, description}: SectionCardProps) => {
  return (
    <div className={styles.card}>
      <img src={icon} alt='section-icon' />
      <p>
        {description}
      </p>
    </div>
  )
}

export const Section3 = () => {
  return (
    <div className={styles.section}>
      <Fade top>
        <h3>We could be active in various ways:</h3>
      </Fade>
      <Fade right>
        <div className={styles.list}>
          {data.map((item, index) => <SectionItem key={`section-${index}`} {...item}/>)}
        </div>
      </Fade>
    </div>
  )
}

import Fade from 'react-reveal/Fade';
import LandingImg2 from '../../../../assets/about/mark2.png';
import styles from './styles.module.scss';

export const Section2 = () => {
  return (
    <div className={styles.section}>
      <div className={styles.topSection}>
        <Fade right>
          <img className={styles.landingImage2} src={LandingImg2} alt='landing-img2' />
        </Fade>
      </div>
      <div className={styles.bottomSection}>
        <Fade top>
          <h3>HOW DO WE GET ENGAGED?</h3>
          <p>
            We are active investors. We invest either money, time or both (the most common scenario). We work with PEs, VCs and startups in various stages: growth, pivoting, exits, restructuring and business reengineering. The defining approach is that we never work only for salary. We work with a long-term incentive that means if we add value, everyone wins!
          </p>
        </Fade>
      </div>
    </div>
  )
}

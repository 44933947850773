import classNames from 'classnames';
import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

const data = [
  {
    icon: require('../../../../assets/about/team-icon.png'),
    title: 'Team',
    subtitle: '*The most important factor. ',
    description: 'If this does not match what we look for, we pass irrespective of the idea or achievements. Life is too short to spend it with the wrong people!',
  },
  {
    icon: require('../../../../assets/about/adding-icon.png'),
    title: 'Adding Value',
    subtitle: '',
    description: 'Are we able to add value to your business?',
  },
  {
    icon: require('../../../../assets/about/objective-icon.png'),
    title: 'Objective',
    subtitle: '',
    description: 'Is the idea/vision big and bold enough to be worthwhile? And is there scope for significant growth and adoption worldwide.',
  },
  {
    icon: require('../../../../assets/about/platform-icon.png'),
    title: 'Platform business',
    subtitle: '',
    description: 'is there a potential to become the industry platform?',
  }
];

interface SectionCardProps {
  className?: any,
  icon: string,
  title: string,
  subtitle: string,
  description: string,
};

const SectionCard = ({className, icon, title, subtitle, description}: SectionCardProps) => {
  return (
    <Fade right>
      <div className={classNames(className, styles.card)}>
        <img src={icon} alt='section-icon' />
        <div className={styles.cardSection}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
        <p className={styles.description}>
          {description}
        </p>
      </div>
    </Fade>
  )
}
export const Section6 = () => {
  return (
    <div className={styles.section}>
      <div className={styles.topSection}>
        <Fade top>
          <h3>
            WHAT IS OUR<br/>PHILOSOPHY?
          </h3>
          <p>
            What drives us is working with great people (the best) and building value (massive value). So, when we choose what to spend our time and money on, we look for the following in the order provided: 
          </p>
        </Fade>
      </div>
      <div className={styles.subSection1}>
        <SectionCard {...data[0]}/>
        <SectionCard className={styles.leftMargin} {...data[1]}/>
      </div>
      <div className={classNames(styles.subSection1, styles.leftMargin)}>
        <SectionCard {...data[2]}/>
        <div className={styles.flexCol}>
          <SectionCard {...data[3]}/>
          <Fade right>
            <span className={styles.footer}>*We do not get involved in companies that are yet to launch their product/service/platform. We would like to see some traction before you get in touch with us</span>
          </Fade>
        </div>
      </div>
    </div>
  )
}

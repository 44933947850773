import Fade from 'react-reveal/Fade';
import LandingImg1 from '../../../../assets/landing-mark1.png';
import LandingImg2 from '../../../../assets/landing-mark2.png';
import styles from './styles.module.scss';

export const Section2 = () => {
  return (
    <div className={styles.section}>
      <Fade left>
        <img className={styles.landingImage1} src={LandingImg1} alt='landing-img1' />
      </Fade>
      <Fade top>
        <p>
          Yobi partners up with world class
          teams to disrupt industries, creating
          sustainability and efficiency!
        </p>
      </Fade>
      <Fade right>
        <img className={styles.landingImage2} src={LandingImg2} alt='landing-img2' />
      </Fade>
    </div>
  )
}

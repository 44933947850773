import {useLocation} from 'react-router-dom';
import { NavHashLink as Link } from 'react-router-hash-link';
import styles from './styles.module.scss';
import AppLogo from '../../assets/logo.svg';
import classNames from 'classnames';
import {NavItems} from './data';
import { MobileMenu } from './mobile';
const Header = () => {
  const {pathname} = useLocation();

  return (
    <div className={styles.header}>
      <a href='/'>
        <img src={AppLogo} alt='app-logo' />        
      </a>
      <div className={styles.navBar}>
        {NavItems.map(item => {
          if (item.title === 'Contact') {
            return (
              <a className={classNames(styles.navItem, pathname === item.path && styles.selected)} href="mailto: toni@yobipartners.com">
                {item.title}
              </a>
            )
          }
          return (
            <Link key={item.path} smooth to={item.path} className={classNames(styles.navItem, pathname === item.path && styles.selected)}>
              {item.title}
            </Link>
          )}
        )}
      </div>
      <MobileMenu />
    </div>
  )
};

export default Header;


import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

export const Section3 = () => {
  const onSubmit = () => {

  }

  return (
    <Fade>
      <div className={styles.section}>
        <span className={styles.sectionTitle}>
          Never miss an update
        </span>
        <p>
          Get our post sent straight to your inbox
        </p>
        <div className={styles.sectionInput}>
          <input className={styles.inputField} type='text' placeholder='Email address' />
          <button className={styles.button} onClick={onSubmit}>Count me in</button>
        </div>
      </div>
    </Fade>
  )
}
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import classNames from 'classnames';
import Fade from 'react-reveal/Fade';
import LeftArrow from '../../../../assets/news/left-arrow.svg';
import RightArrow from '../../../../assets/news/right-arrow.svg';
import styles from './styles.module.scss';

import NewsService from "../../../../services/news.service";
import {INewsData} from "../../../../types/news.type";
import { API_BASE_URL } from "../../../../http";
import { convertFromISODateWithFormat } from "../../../../utils";

type SectionParam = {
  id?: string;
  data?: INewsData;
};

export const Section1 = ({id, data}: SectionParam) => {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState<INewsData | undefined>(data);

  useEffect(() => {
    if (id) {
      NewsService.get(id).then(response => {
        if (response.data.data) {
          const _newsData = response.data.data;
          setNewsData(_newsData);
        }
      })
    } else if (data) {
      setNewsData(data);
    }
  }, [id, data]);

  const handleBack = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(-1);
  };

  const onPressReadMore = () => {
    if (id === undefined && data?._id) {
      navigate(`/news/${data?._id}`)
    }
  };

  return (
    <div className={classNames(styles.section, id === undefined && styles.pointer)} onClick={onPressReadMore}>
      <div className={styles.backButtonContainer}>
        {id !== undefined && (
          <div className={styles.backButton} onClick={handleBack}>
            <img src={LeftArrow} alt='left-arrow' />
            <p>Back</p>
          </div>
        )}
      </div>
      {newsData ? (
        <div>
          <Fade right>
            <div className={styles.imgSection}>
              <img className={styles.thumbnail} src={`${API_BASE_URL}${newsData?.thumbnail}`} alt='thumbnail' />
              <img className={styles.mainImage} src={`${API_BASE_URL}${newsData?.bannerImage}`} alt='main-img' />
            </div>
          </Fade>
          <div className={styles.timeSection}>
            <p>{`${convertFromISODateWithFormat(newsData?.updatedAt || '', 'MMM DD YYYY')} . ${newsData?.length} min read`}</p>
          </div>
          <Fade right>
            <div className={styles.contentSection}>
              <h3 className={styles.title}>
                {newsData?.title}
              </h3>
              <div className={styles.descriptionSection}>
                <p className={classNames(styles.description, id === undefined && styles.moreDescription)}>
                  {newsData?.description}
                </p>
                {id === undefined && (
                  <div className={styles.readMoreSection} onClick={onPressReadMore}>
                    <div className={styles.readMore}>
                      <p>Read more</p>
                      <img src={RightArrow} alt='right-arrow' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fade>
        </div>
      ) : (
        <h1>No news</h1>
      )}
    </div>
  )
}

import styles from './styles.module.scss';
const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.left}>
        <span className={styles.contactUs}>Contact us</span>
        <span className={styles.title}>
          Want to start a new project? <br/>
          or just say hello?
        </span>
        <span className={styles.info}>
          toni@yobipartners.com
        </span>
      </div>
      <div className={styles.right}>
        <div className={styles.flexRow}>
          <a href="/about" className={styles.item}>About us</a>
          <a href="/news" className={styles.item}>News</a>
        </div>
        <div className={styles.flexRow}>
          <a href="mailto: toni@yobipartners.com" className={styles.item}>Contact us</a>
          <a href="https://www.linkedin.com/company/yobi-partners/" className={styles.item} target="_blank" rel="noreferrer">Linkedin</a>
        </div>
      </div>
      <span className={styles.copyRight}>© 2020 YOBI PARTNERS</span>
    </div>
  )
};

export default Footer;


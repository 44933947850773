import { FileUploader } from "react-drag-drop-files";

type DropzoneProps = {
  fileTypes: string[];
  multiple: boolean;
  handleChange: (file: File) => void;
  children: React.ReactNode;
}

const DropZone = ({fileTypes, multiple, handleChange, children}: DropzoneProps) => {
  return (
    <FileUploader 
      handleChange={handleChange}
      multiple={multiple}
      name="file" 
      types={fileTypes} 
      hoverTitle="Drop Here!"
      children={children}
    />
  );
}
export default DropZone;
import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router-dom';
import LeftArrow from '../../../../assets/news/left-arrow.svg';
import RightArrow from '../../../../assets/news/right-arrow.svg';
import { API_BASE_URL } from '../../../../http';
import { INewsData } from '../../../../types/news.type';
import { convertFromISODateWithFormat } from '../../../../utils';
import styles from './styles.module.scss';

const SectionItem = ({_id, thumbnail, title, length, updatedAt}: INewsData) => {
  const navigate = useNavigate();
  const handleSectionItem = (e: React.MouseEvent<HTMLDivElement>) => {
    navigate(`/news/${_id}`);
  }
  return (
    <Fade right>
      <div className={styles.sectionItem} onClick={handleSectionItem}>
        <img src={`${API_BASE_URL}${thumbnail}`} alt='thumbnail' />
        <div className={styles.itemContent}>
          <span className={styles.itemTitle}>
            {title}
          </span>
          <div className={styles.itemFooter}>
            <p>{convertFromISODateWithFormat(updatedAt || '', 'MMM DD YYYY')} . {length} min read</p>
            <img src={RightArrow} alt='right-arrow' />
          </div>
        </div>
      </div>
    </Fade>
  )
};

export const Section2 = ({data}: {data: INewsData[]}) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>
          The latest
        </span>
        {/* <div className={styles.sectionAction}>
          <img src={LeftArrow} alt='left-arrow' />
          <p>1 / 4</p>
          <img src={RightArrow} alt='right-arrow' />
        </div> */}
      </div>
      <div className={styles.sectionBody}>
        {data.map((item, index) => <SectionItem key={`section-mock-${index}`} {...item} />)}
      </div>
    </div>
  )
}
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavHashLink as Link } from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';

import AppLogo from '../../assets/logo-white.svg';
import HamburgerMenu from '../../assets/hamburger-menu.svg';
import CloseIcon from '../../assets/close-icon.svg';
import styles from './styles.module.scss';
import { NavItems } from './data';

export const MobileMenu = () => {
  const {pathname} = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className={styles.mobileNav}>
      <div onClick={() => setOpenMenu((prev) => !prev)}>
        <img src={HamburgerMenu} alt='hamburger' />
      </div>
      <Fade when={openMenu}>
        {openMenu && (
        <div className={styles.openContainer}>
          <div className={styles.topBar}>
            <a href='/'>
              <img src={AppLogo} alt='app-logo' />        
            </a>
            <div onClick={() => setOpenMenu((prev) => !prev)}>
              <img src={CloseIcon} alt='hamburger' />
            </div>
          </div>
          <div className={styles.menuBarWrapper}>
            <div className={styles.menuBar}>
              {NavItems.map(item => {
                if (item.title === 'Contact') {
                  return (
                    <a className={classNames(styles.menuItem, pathname === item.path && styles.selected)} href="mailto: toni@yobipartners.com">
                      {item.title}
                    </a>
                  )
                }
                return (
                <Link key={item.path} smooth to={item.path} className={classNames(styles.menuItem, pathname === item.path && styles.selected)}>
                  {item.title}
                </Link>
              )})}
            </div>
          </div>
        </div>
        )}
      </Fade>
    </div>
  )
};

import Fade from 'react-reveal/Fade';
import RotateLogo from '../../../../assets/rotate-logo.png';
import ArrowDown from '../../../../assets/arrow-down.svg';
import styles from './styles.module.scss';

interface Section1Props {
  onScrollDown: () => void,
};

export const Section1 = ({onScrollDown}: Section1Props) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <Fade left>
          <h1>{'CO-CREATING\nTHE FUTURE OF\nDIGITAL'}</h1>
        </Fade>
        <Fade right>
          <span>
            A strategy and investment firm that invest our<br/>
            own time and money in businesses that have<br/>
            the potential to change an industry on a<br/>
            global scale.
          </span>
        </Fade>
      </div>
      <Fade bottom>
        <div className={styles.scrollContainer} onClick={onScrollDown}>
          <div className={styles.rotateContainer}>
            <img className={styles.rotate} src={RotateLogo} alt='rotate' />
            <span>Yi</span>
          </div>
          <img className={styles.arrowDown} src={ArrowDown} alt='arrow-down' />
        </div>
      </Fade>
    </div>
  )
}

import Footer from "../footer";
import Header from "../header";

interface LayoutDefaultProps {
  children: React.ReactNode;
};

const LayoutDefault = ({children}: LayoutDefaultProps) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <Footer />
  </>
);

export default LayoutDefault;

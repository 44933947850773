import Fade from 'react-reveal/Fade';
import LandingImg1 from '../../../../assets/about/mark1.png';
import styles from './styles.module.scss';

export const Section1 = () => {
  return (
    <div className={styles.section}>
      <div className={styles.left}>
        <Fade left>
          <h1>WE FOCUS ON COMPANIES THAT USE DATA AND AUTOMATION</h1>
        </Fade>
        <Fade left>
          <img className={styles.landingImage1} src={LandingImg1} alt='landing-img1' />
        </Fade>
      </div>
      <div className={styles.right}>
        <Fade right>
          <span>
          Those companies should encompass enabling technologies such as:<br/><br/>

          Big Data, AI, AR, Computer Vision, IoT, DLT (Decentralised Technologies).<br/><br/>

          We look across all industries excluding Biotech and Pharma.<br/><br/>

          We are interested in businesses that create sustainability and benefits to the environment. 
          </span>
        </Fade>        
      </div>  
    </div>
  )
}

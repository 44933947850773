import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutPage from './pages/about';
import AdminNewsCreatePage from './pages/admin/create';
import AdminNewsPage from './pages/admin/news';
import Custom404Page from './pages/custom-404';
import HomePage from './pages/home';
import NewsPage from './pages/news';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/news' element={<NewsPage />} />
        <Route path='/news/:newsId' element={<NewsPage />} />
        <Route path='/admin/news' element={<AdminNewsPage />} />
        <Route path='/admin/news/create' element={<AdminNewsCreatePage />} />
        <Route path='/admin/news/edit/:newsId' element={<AdminNewsCreatePage />} />
        <Route path='*' element={<Custom404Page />} />
      </Routes>
    </Router>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LayoutDefault from "../../layout/layout-default";
import { Section1 } from "./components/section1";
import { Section2 } from "./components/section2";
import { Section3 } from "./components/section3";
import styles from './styles.module.scss';
import NewsService from "../../services/news.service";
import { INewsData } from "../../types/news.type";

const NewsPage = () => {
  const {newsId} = useParams();
  const [newsData, setNewsData] = useState<INewsData[]>([]);
  const [firstNews, setFirstNews] = useState<INewsData>();

  useEffect(() => {
    if (newsId) {
      window.scroll({
        top: 0,
        left: 0, 
        behavior: 'smooth',
      });
    } else {
      NewsService.getAll()
      .then(response => {
        if (response.data.list) {
          const newsList = response.data.list;
          if (newsList.length > 0) {
            setFirstNews(newsList[0]);
            setNewsData(newsList.filter((item, index) => {
              return index !== 0;
            }));
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
    }
  }, [newsId]);

  return (
    <LayoutDefault>
      <div className={styles.container}>
        <Section1 id={newsId} data={firstNews} />
        {newsId === undefined && <Section2 data={newsData} />}
        <Section3 />
      </div>
    </LayoutDefault>
  )
};

export default NewsPage;

import http from '../http';
import {INewsResponse} from '../types/news.type';

class NewsDataService {
  getAll() {
    return http.get<INewsResponse>("/api/news");
  }

  get(id: string) {
    return http.get<INewsResponse>(`/api/news/${id}`);
  }

  create(data: FormData) {
    return http.post<INewsResponse>("/api/news", data);
  }

  update(data: FormData, id: any) {
    return http.put<INewsResponse>(`/api/news/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/api/news/${id}`);
  }
}

export default new NewsDataService();
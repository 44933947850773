
export const Portfolios = [
  {
    thumb: require('../assets/portfolios/Simplygon.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Simplygon - 3D Graphics',
    description: `Simplygon, founded in Sweden, revolutionised the compression of 3D graphics with focus on high-end gaming and VR/AR production. Simplygon was acquired by Microsoft in 2017.`,
    link: 'https://www.simplygon.com/'
  },
  {
    thumb: require('../assets/portfolios/Cortopia-winner.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Cortopia Studios - VR Gaming',
    description: `Cortopia Studios was one of the pioneers in the VR-gaming industry and won IMGA Best Global VR Game in 2016 for Wands. Cortopia is now a listed company.`,
    link: 'https://www.cortopia.com/'
  },
  {
    thumb: require('../assets/portfolios/Univrses.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Univrses - Computer Vision and AI',
    description: `Sweden-based Universe’s is a Computer Vision and AI company focused on building the autonomous future. Partnering with leading Nordic city projects working to transform society and industries in a smart city environment.`,
    link: 'http://www.univrses.com/'
  },
  {
    thumb: require('../assets/portfolios/3 Temp-award.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: '3TEMP - Coffee brewer',
    description: `A Sweden-based company that has developed the best coffee brewer in the industry on top of state-of-art design and IoT platform for support and analytics. This is the Tesla of the coffee industry.`,
    link: 'https://3temp.com/'
  },
  {
    thumb: require('../assets/portfolios/Utopia.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Utopia - Simplifying royalty collection',
    description: `Switzerland-based Utopia Music has created a state-of-the-art platform to listen to all music played on any medium in the world and organise all that data to simplify royalty collection and payouts.`,
    link: 'https://utopiamusic.com/'
  },
  {
    thumb: require('../assets/portfolios/Yumi-winner.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Yumi - Clean Nutrition',
    description: `An award winning, organic baby food company providing certified clean nutrition to babies. 
    Yumi works alongside well know chefs to create organic foods which are organic with no preservatives or junk.`,
    link: 'https://helloyumi.com/',
  },
  {
    thumb: require('../assets/portfolios/Urban Oasis.png'),
    icon: require('../assets/portfolios/portfolio-symbol2.png'),
    title: 'Urban Oasis - Vertical farming company',
    description: `A Swedish vertical farming company that is using AI and data to optimise the farming process with impressive benefits for the consumer, the environment and retailers`,
    link: 'https://www.urbanoasis.life/'
  },
  {
    thumb: require('../assets/portfolios/Deepnorth.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Deep North - Computer Vision and AI',
    description: `Deep North Inc. is a Silicon Valley-based Computer Vision and AI company delivering analytics for the physical world (e.g. retail, real-estate and public sector)`,
    link: 'https://www.deepnorth.com/'
  },
  {
    thumb: require('../assets/portfolios/Connectd.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Connectd - A growth marketplace',
    description: `Underpinned by smart matching technology, our platform directly connects individuals with the UK's leading Founders, Investors, NEDs, Board Advisors and Mentors.`,
    link: 'https://www.connectd.co/'
  },
  {
    thumb: require('../assets/portfolios/RWRD.png'),
    icon: require('../assets/portfolios/portfolio-symbol1.png'),
    title: 'Rwrd - A smart loyalty platform',
    description: `Enabling businesses to eliminate paper and connect with customers directly through their smartphone using a lightweight technology at the point of sale enabling customers to effortlessly collect stamps towards a customised rewards program.`,
    link: 'https://rwrdapp.com/'
  },
  {
    thumb: require('../assets/portfolios/Lurkit.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Lurkit - Modern gaming platform',
    description: `Lurkit is a cutting edge gaming platform that connects publishers, studios and content creators within the gaming industry, creating data-based collaborations. Its exceptional gaming analytic tools, allows games to grow and succeed.`,
    link: 'https://www.lurkit.com/'
  },
  {
    thumb: require('../assets/portfolios/GMP.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'GMP - Spend analytics',
    description: `GMP Systems delivers a SaaS platform for the media industry to help manage spend and provide analytics across the campaign lifecycle.`,
    link: 'https://www.gmp-systems.com/'
  },
  {
    thumb: require('../assets/portfolios/Nexl.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'NEXL - CRM',
    description: `NEXL use the latest AI intelligence to capture CRM data to turn business relationships into revenue. A cutting edge operations platform designed for law firms facilitating the seamless capture of every interaction, with data-driven analytics`,
    link: 'https://nexl.cloud/',
  },
  {
    thumb: require('../assets/portfolios/Estate logs.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Estate Logs - Digitise Real-Estate',
    description: `Estate Logs has created a SaaS platform to digitise the management of real-estate. It's analytics, operational predictions provide cost control, sustainability and transparency for the customer and their partners.`,
    link: 'https://www.estatelogs.com/'
  },
  {
    thumb: require('../assets/portfolios/Tailsweep.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Tailsweep - Social Media',
    description: `Tailsweep was the first blog network in the Nordics and pioneered the influencer world before it even existed. Tailsweep was acquired by Bonnier Media Group.`,
    link: ''
  },
  {
    thumb: require('../assets/portfolios/Contour.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'Contour - Revolutionising trade finance',
    description: `Based in Singapore, Contour is revolutionising the Trade Finance industry through a blockchain-based platform.`,
    link: 'https://www.contour.network/'
  },
  {
    thumb: require('../assets/portfolios/Ipendo.png'),
    icon: require('../assets/portfolios/portfolio-symbol3.png'),
    title: 'IPENDO - Intellectual Property',
    description: `Ipendo, was a pioneer in the SaaS-based Intellectual Property Management and Services market. Ipendo was acquired by CPA Global Limited (the largest company in the IP industry) in 2011.`,
    link: 'https://clarivate.com/cpaglobal-is-now-clarivate/?lid=c'
  },
];

import { Portfolio } from "../../components/portfolio";
import LayoutDefault from "../../layout/layout-default";
import {Section1} from "./components/section1";
import {Section2} from "./components/section2";
import {Section3} from "./components/section3";
import {Section4} from "./components/section4";
import styles from './styles.module.scss';

const HomePage = () => {
  const onScrollDown = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  };

  return (
    <LayoutDefault>
      <div className={styles.container}>
        <Section1 onScrollDown={onScrollDown}/>
        <Section2 />
        <Section3 />
        <Section4 />
        <Portfolio />
      </div>
    </LayoutDefault>
  )
};

export default HomePage;

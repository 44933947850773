import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
import styles from './styles.module.scss';
import './slider.css';

interface SectionCardProps {
  title: string;
  description: string;
}

const data = [
  {
    title: 'NETWORK',
    description: 'We are a network of founders and entrepreneurs that have been through several journeys each. We have been in everything from small startups to multi-billion-dollar enterprises. ',
  },
  {
    title: 'EXPERIENCE',
    description: 'We have worked with angel investors, Venture Capital and Private Equity. We have worked in Europe, North America and APAC. We have raised money from the hundreds of thousands to hundreds of millions. ',
  },
  {
    title: 'WITH WHO',
    description: 'We have done the jobs of any CXO and worn multiple hats in various startups. We have failed plenty. We have seen things go horribly wrong, but we have seen lots go fantastically well, also.',
  },
];

const SliderCard = ({title, description}: SectionCardProps) => {
  return (
    <div className={styles.card}>
      <h3>{title}</h3>
      <p>
        {description}
      </p>
    </div>
  )
}

export const Section3 = () => {
  const settings = {
    centerMode: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    }]
  };
  return (
    <div className={styles.section}>
      <Fade right>
        <Slider {...settings}>
          {data.map((item, index) => <SliderCard key={`slider-${index}`} {...item}/>)}
        </Slider>
      </Fade>
    </div>
  )
}

import { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import IconKey from '../../assets/key-icon.svg';
import {Portfolios} from '../../data/portfolio';
import styles from './styles.module.scss';
import InvestmentStageModal from './components/modal';

interface PortfolioCardProps {
  thumb: string;
  icon: string;
  title: string;
  description: string;
  link?: string;
}
const PortfolioCard = ({thumb, icon, title, description, link}: PortfolioCardProps) => {
  return (
    <Fade right>
      <a href={link || ''} target="_blank" rel="noreferrer">
        <div className={styles.portfolioCard}>
          <img className={styles.thumb} src={thumb} alt='thumb' />
          <img className={styles.symbol} src={icon} alt='symbol' />
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </a>
    </Fade>
  )
}

export function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

export const Portfolio = () => {
  const TOTAL_NUMBER = 8;
  const scrollRef = useHorizontalScroll();
  const [slideIndex, setSlideIndex] = useState(0);
  const [sliderGap, setSliderGap] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const percentage = (slideIndex + sliderGap + 1) * 100 / TOTAL_NUMBER;

  const handleKeyModal = () => {
    setShowModal(true);
  };

  return (
    <div id='portfolio' className={styles.section}>
      <Fade top>
        <div className={styles.rowFlex}>
          <h3>
            PORTFOLIO<br/>COMPANIES
          </h3>
          <div className={styles.keyContainer} onClick={handleKeyModal}>
            <img src={IconKey} alt='icon-key' />
          </div>
        </div>
      </Fade>
      <div className={styles.portfoioSection}>
        {/* <div className={styles.progressBar}>
          <div className={styles.activeProgressBar} style={{width: `${percentage}%`}} />
        </div> */}
        <div className={styles.sliderContainer} ref={scrollRef}>
          <div className={styles.slider}>
          {
            Portfolios.map((item, index) => (
              <PortfolioCard key={`portfolio-${index}`} {...item} />
            ))
          }
          </div>
        </div>
      </div>
      {showModal && <InvestmentStageModal onClose={() => setShowModal(false)}/>}
    </div>
  )
}

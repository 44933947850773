import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';
import ImageMask from '../../../../assets/about/mask3.png';

export const Section5 = () => {
  return (
    <div className={styles.section}>
      <Fade>
        <img src={ImageMask} alt='mask-img' />
      </Fade>
    </div>
  )
}
